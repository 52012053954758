<template>
	<div class="container-fluid animatedParent animateOnce">
		<!-- main content -->
	
		<div class="card mt-3 shadow">
			<div class="card-header white">
				<h6>会员资料</h6>
			</div>
			<div class="card-body">
				<div class="needs-validation" novalidate="">
					<div class="form-row">
						<div class="col-md-3 mb-3">
							<label for="">级别</label>
							<select class="form-control" v-model="customer.group_id">
								<option v-bind:key="index" :value="item.id"  v-for="(item,index) in customergrouplist">{{item.name}}</option>
							</select>
						</div>
						
						<div class="col-md-3 mb-3">
							<label for="validationCustom01">会员名</label>
							<input v-model="customer.customer_name" type="text" class="form-control"  placeholder="会员名">
							<div class="valid-feedback">
								Looks good!
							</div>
						</div>
						
						
						<div class="col-md-3 mb-3">
							<label for="validationCustom01">工号</label>
							<input v-model="customer.staff_no" type="text" class="form-control"  placeholder="工号">
							<small class="form-text text-muted">
								创建后不可修改，唯一，不可重复
							</small>
						</div>
						
						<div class="col-md-3 mb-3">
							<label for="validationCustomUsername">手机</label>
							<input v-model="customer.phone_number" type="number" class="form-control" placeholder="手机号码">
							<small class="form-text text-muted">
								非常重要，手机号必须唯一
							</small>
						</div>
						
						
						
						
					</div>
					<div class="form-row">
						
						<div class="col-md-3 mb-3">
							<label for="validationCustomUsername">卡号</label>
							<input v-model="customer.customer_code" type="number" class="form-control" placeholder="卡号">
							<small class="form-text text-muted">
								卡面号码
							</small>
						</div>
						<div class="col-md-3 mb-3">
							<label for="">卡片芯片ID</label>
							<input v-model="customer.customer_sn" type="text" class="form-control" placeholder="卡片芯片ID">
							<small class="form-text text-muted">
								卡片内置芯片ID（刷卡可获得）
							</small>
						</div>
						
						<div class="col-md-3 mb-3">
							<label for="validationCustom03">余额({{setting.customer_balance_type==1?'次':'元'}})</label>
							<input v-model="customer.balance" type="number" class="form-control" placeholder="余额">
							<!-- <small class="form-text text-muted">
								储值卡输入金额大小，次卡输入次数
							</small> -->
							<div class="ml-4 mt-1">
								<label class="form-check-label">
									<input v-model="customer.is_gift" value="1" class="form-check-input" type="checkbox"> 补贴
								</label>
							</div>
						</div>
						<div class="col-md-3 mb-3">
							<label for="validationCustom02">密码</label>
							<input v-model="customer.password" type="text" class="form-control" placeholder="会员密码">
							<small class="form-text text-muted">
								不设置请留空
							</small>
						</div>
						
						<!-- <div class="col-md-3 mb-3">
							<label for="validationCustom05">状态</label>
							<br>
							
							<div class="mt-1">
								<input v-model="customer.enabled" id="status_1" type="radio" name="status" value="1" class="">
								正常
								<input v-model="customer.enabled" id="status_0" type="radio" name="status" value="0" class="">
								挂失
							
								<input v-model="customer.enabled" id="status__1" type="radio" name="status" value="-1" class="">
								注销
							</div>	
						</div> -->
					</div>
					
					<div class="form-row">
						<div class="col-md-3 mb-3">
							<label for="validationCustom04">过期日期</label>
							<!-- <div class="input-group">
								<input v-model="customer.expired_date" type="text" id="expiry_date" class="date-time-picker form-control">
								<span class="input-group-append">
									<span class="input-group-text add-on white">
										<i class="icon-calendar"></i>
									</span>
								</span>
							</div> -->
							<br>
							<date-picker
								  v-model="customer.expired_date"
								  format="YYYY-MM-DD"
								  type="date" 
								  input-class="form-control" 
								  :editable="false" :clearable="true"
								></date-picker>
							<small class="form-text text-muted">
								不填写表示永不过期
							</small>
						</div>
					</div>
					
					<div class="form-row">
						<div class="col-md-6 mb-3">
							<label for="">会员标签TAG</label>
							<br>
							
							<input  v-model="customer.tag" type="text" class="tags-input form-control" style="width: 100%;">
							   
							<small class="form-text text-muted">
								输入完一个标签后，按回车，最多5个
							</small>
						</div>
					</div>
					
					<div class="form-row">
						<div class="col-md-3 mb-3">
							<label for="validationCustom05">状态</label>
							<br>
							
							<div class="mt-1">
								<input v-model="customer.enabled" id="status_1" type="radio" name="status" value="1" class="">
								正常
								<input v-model="customer.enabled" id="status_0" type="radio" name="status" value="0" class="">
								挂失
							
								<input v-model="customer.enabled" id="status__1" type="radio" name="status" value="-1" class="">
								注销 
							</div>	
						</div>
					</div>	
					
					<!-- <div class="form-group">
						<div class="form-check">
							<input class="form-check-input" type="checkbox" value="" id="invalidCheck" required="">
							<label class="form-check-label" for="invalidCheck">
								Agree to terms and conditions
							</label>
							<div class="invalid-feedback">
								You must agree before submitting.
							</div>
						</div>
					</div> -->
					<!-- <button v-if="customer.id>0" @click="remove" type="button" class="btn btn-dark"> 删除 </button> -->
					<button @click="save" :disabled="disabled" type="button" class="btn btn-primary"> 保存 </button>
				</div>
	
	
			</div>
		</div>
	
		<!-- //main content -->
	</div>
</template>

<script>
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';
	import 'vue2-datepicker/locale/zh-cn';
	
	export default {
		data(){
			return{
				title: '添加会员',
				
				token: '',
				outlet_id: 0,
				outlet_name:'',
				user_name: '',
				
				customer: {
					customer_name: '',
					phone_number: '',
					customer_code: '',
					customer_sn: '',
					group_id: 0,
					password: '',
					
					balance: 0,
					is_gift: false,
					expired_date: '',
					enabled: 1,
					shared: 0,
				},
				
				disabled:  false,
				
				customergrouplist: [],
				customergroup:{},//选中的会员级别
				
				setting: {},
			}
		},
		
		components: { DatePicker },
		
		created: function() {
			console.log('--- created --- ')
			let self = this;
			
			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;
			
			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			console.log(lsLoginData.logined_outlet_name)
			// self.outlet_id = lsLoginData.logined_outlet_id;
			console.info('--- this.$store.state ->',this.$store.state)
			self.outlet_id = this.$store.state.current_outlet_id || lsLoginData.logined_outlet_id;
			self.outlet_name = lsLoginData.logined_outlet_name;
			self.user_name = lsLoginData.user_account;
			
			self.setting = JSON.parse(localStorage.getItem('SETTING'));
			
			self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
			console.log('--- outlet list ->', self.outletlist)
			
			//指定店铺
			// let selectedOutletId = getQueryString("outlet_id");
			console.log('--- this.$route>',this.$route)
			let selectedOutletId = this.$route.params.outlet_id;
			if(selectedOutletId){
				self.outlet_id = selectedOutletId;
			}
			
		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;
			
			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			if (!self.token) {
				console.log('--- mounted jump  --- ')
				location.href = 'index.html';
				return;
			}
			
			self.initData();
		},
		methods: {
			initData(){
			 	let self = this;
			 	
			 	//取得员工列表
			 	self.axios.get(self.GLOBAL.baseURI + 'getCustomerGroupList', {
			 		params: {
			 			token: self.token,
			 			outlet_id: self.outlet_id,
			 			// query: self.query,
			 			// current_page: self.current_page,
			 			// page_size: self.page_size,
			 		}
			 	})
			 	.then(function(response) {
			 		console.log(response);
			 		return response.data;
			 	})
			 	.catch(function(error) {
			 		console.log(error);
			 	})
			 	.then(function(data) {
			 		// always executed
			 		console.log(data.data);
			 		if (data.code == 200) { 
			 			self.customergrouplist = data.data;
						
						if(self.customergrouplist.length>0){
							self.customer.group_id = self.customergrouplist[0].id || 0;//默认第一个
						}
						else{
							alert('该店铺没有会员级别');
						}
						
			 			// __LIST = data.data;
			 		}
			 		else if (data.code == 101) { 
			 			//登录超时
			 			location.href = "index.html";
			 		}
			 		else{
			 			alert(data.message)
			 		}
			 	});
			 	//END
			 	
			 },
			
			
			save(){
				let self = this;
				console.log(self.customer);
				// return;
				
				//校验
				if(self.customer.group_id==0){
					alert('请选择会员级别')
					return;
				}
				
				if(!self.customer.customer_name){
					alert('会员名不能为空')
					return;
				}
				
				if(!self.customer.staff_no){
					alert('工号不能为空')
					return;
				}
				
				if(!self.customer.phone_number){
					alert('手机号不能为空')
					return;
				}
				
				if(!self.customer.customer_code){
					alert('卡号不能为空')
					return;
				}
				
				if(!self.customer.customer_sn){
					alert('卡片芯片ID不能为空')
					return;
				}
				
				self.disabled = true;
				
				let tags = $(".tags-input").tagsinput('items');
				let tag = tags.length>0?tags.join():'';
				
				// 组装数据
				let postData = {
					token: self.token,
					outlet_id: self.outlet_id,
					
					customer_name: self.customer.customer_name,
					staff_no: self.customer.staff_no,
					phone_number: self.customer.phone_number,
					customer_code: self.customer.customer_code,
					customer_sn: self.customer.customer_sn,
					group_id: self.customer.group_id,
					balance: self.customer.balance,
					is_gift: self.customer.is_gift?1:0,
					password: self.customer.password,
					expired_date: self.customer.expired_date,
					enabled: self.customer.enabled!=undefined?self.customer.enabled:1,
					shared: self.customer.shared,
					tag: tag,
				}
				
				self.axios.get(self.GLOBAL.baseURI + 'addCustomer', {
					params: postData
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.disabled = false;
					
					if (data.code == 200) {
						alert('保存成功')
						// location.href = 'customers.html'
						self.$router.push({ path: 'customers', params: { outlet_id: self.outlet_id }})
					}
					else if (data.code == 101) {
						//登录超时
						location.href = "index.html";
					}
					else if (data.code == 4001) {
						alert('该手机号会员已存在')
					}
					else{
						alert(data.message)
					}
				});
			},
			
			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;
			
				self.initData();
			},
		},
	}
	
	jQuery(function(){
	// 	console.log('--- 初始化日期控件 ---')
	// 	 jQuery('#expiry_date').datetimepicker({
	// 	  format:'Y-m-d',
	// 	  // onShow:function( ct ){
	// 	  //  this.setOptions({
	// 	  //   maxDate:false
	// 	  //  })
	// 	  // },
	// 	  timepicker:false,
	// 	  onChangeDateTime:function(dp,$input){
	// 		console.log($input.val())
	// 		//触发 input事件,v-model 才会起作用
	// 		document.getElementById('expiry_date').dispatchEvent(new Event('input'));
	// 	  }
	//  });
	 
	//  $.datetimepicker.setLocale('ch');
	 
	 
	 $(".tags-input").tagsinput({
		tagClass:"badge badge-primary"
	 })
	 
	});
</script>

<style scoped>
	.bootstrap-tagsinput{
		width: 100% !important;
	}
</style>
